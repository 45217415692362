import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SpinnerComponent } from '../spinner';

@Component({
  selector: 'app-busy-overlay',
  standalone: true,
  imports: [SpinnerComponent],
  templateUrl: './busy-overlay.component.html',
  styleUrl: './busy-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusyOverlayComponent {
  busy = input.required<boolean>();
}
