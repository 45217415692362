import { HttpInterceptorFn, provideHttpClient, withFetch, withInterceptors, } from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';
import { API_URL } from './api-config';
import { ApiService } from './services';

export function provideApi(
  url: string,
  config: { interceptors: HttpInterceptorFn[] }
) {
  return makeEnvironmentProviders([
    provideHttpClient(withFetch(), withInterceptors(config.interceptors)),
    {
      provide: API_URL,
      useValue: url,
    },
    ApiService,
  ]);
}
