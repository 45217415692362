import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { AuthService } from '@features/auth';
import { filter, finalize, from, switchMap } from 'rxjs';
import { BusyOverlayComponent } from 'src/components/busy-overlay';
import { slideInOutAnimation } from '../animations';

@Component({
  selector: 'app-navigation-drawer',
  standalone: true,
  imports: [RouterLink, BusyOverlayComponent],
  templateUrl: './navigation-drawer.component.html',
  styleUrl: './navigation-drawer.component.scss',
  animations: [slideInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDrawerComponent implements OnInit, AfterViewInit {
  #dialogRef = inject(DialogRef);
  #authService = inject(AuthService);
  #destroyRef = inject(DestroyRef);
  #router = inject(Router);

  protected authenticated: boolean = inject(DIALOG_DATA);

  protected animationState = signal<string>('out');
  protected overlayMessage = signal<string>('');
  protected redirectUrl = '/';

  ngOnInit() {
    this.redirectUrl = this.#router.url;

    this.#router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.closeDialog();
    });
  }

  ngAfterViewInit() {
    this.animationState.set('in');
  }

  protected closeDialog() {
    this.animationState.set('out');
    setTimeout(() => this.#dialogRef.close(), 200);
  }

  protected logout() {
    this.overlayMessage.set('Logging out...');

    this.#authService
      .logout()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        switchMap(() => from(this.#router.navigate(['/']))),
        finalize(() => {
          this.overlayMessage.set('');
          this.closeDialog();
        }),
      )
      .subscribe();
  }
}
