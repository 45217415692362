import { Injectable, inject } from '@angular/core';
import { ApiService } from '@features/api';

@Injectable({ providedIn: 'root' })
export class JWTService {
  protected api = inject(ApiService);

  #key = 'jwt';

  get token(): string | null {
    return localStorage.getItem(this.#key);
  }

  save(token: string): void {
    localStorage.setItem(this.#key, token);
  }

  clear(): void {
    localStorage.removeItem(this.#key);
  }
}
