import { ChangeDetectionStrategy, Component, output } from '@angular/core';

@Component({
  selector: 'app-link-button',
  standalone: true,
  imports: [],
  templateUrl: './link-button.component.html',
  styleUrl: './link-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkButtonComponent {
  buttonClick = output();

  onKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopImmediatePropagation();
      this.onButtonClicked();
    }
  }

  onButtonClicked() {
    this.buttonClick.emit();
  }
}
