<a
  tabindex="0"
  (keypress)="onKeyboardEvent($event)"
  (keypress)="onKeyboardEvent($event)"
  (click)="onButtonClicked()"
  class="underline cursor-pointer"
  role="button"
>
  <ng-content></ng-content>
</a>
