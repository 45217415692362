import { Dialog, DialogModule } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  TemplateRef,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { AuthService } from '@features/auth';
import { filter, finalize, from, switchMap } from 'rxjs';
import { BusyOverlayComponent } from '../busy-overlay';
import { NavigationDrawerComponent } from '../navigation-drawer';

@Component({
  selector: 'app-masthead',
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.scss'],
  standalone: true,
  imports: [RouterLink, DialogModule, BusyOverlayComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MastheadComponent implements OnInit {
  protected auth = inject(AuthService);
  #router = inject(Router);
  #dialog = inject(Dialog);
  #destroyRef = inject(DestroyRef);

  protected drawer = viewChild.required<TemplateRef<unknown>>('drawer');
  protected overlayMessage = signal<string>('');
  protected redirectUrl = signal<string>('/');

  ngOnInit() {
    this.#router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event) => this.redirectUrl.set(event.urlAfterRedirects));
  }

  protected openDrawer() {
    this.#dialog.open<void, boolean>(NavigationDrawerComponent, {
      height: '100%',
      width: '100%',
      data: this.auth.authenticated(),
    });
  }

  protected logout() {
    this.overlayMessage.set('Logging out...');

    this.auth
      .logout()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        switchMap(() => from(this.#router.navigate(['/']))),
        finalize(() => this.overlayMessage.set('')),
      )
      .subscribe();
  }
}
