import { InjectionToken } from '@angular/core';

export interface NavigationLink {
  title: string;
  link: string[];
  children?: NavigationLink[];
}

export type NavigationLinks = NavigationLink[];

export const NAVIGATION = new InjectionToken<NavigationLinks>('NAVIGATION');
