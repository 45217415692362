@if (busy()) {
  <div class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
    <div
      class="bg-black text-white border-primary border-2 p-6 rounded text-center flex items-center justify-center gap-2"
    >
      <ng-content></ng-content>
      <app-spinner></app-spinner>
    </div>
  </div>
}
