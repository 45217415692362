import { Injectable, signal, WritableSignal } from '@angular/core';
import { Snack } from './snack';

export interface SnackConfig {
  id: number;
  snack: Snack;
  removing: WritableSignal<boolean>;
  prepForRemove: () => void;
  remove: () => void;
}

@Injectable({ providedIn: 'root' })
export class SnackBar {
  snacks = signal<Map<number, SnackConfig>>(new Map());

  add(snack: Snack) {
    const id = Date.now() - Math.random();

    const config = {
      id,
      snack,
      removing: signal<boolean>(false),
      prepForRemove: () => {
        clearTimeout(removingTimeout);

        this.snacks.update((snacks) => {
          const snack = snacks.get(id);
          if (snack) {
            snack.removing.set(true);
            snacks.set(id, snack);
          }
          return snacks;
        });
      },
      remove: () => {
        clearTimeout(timeout);

        this.snacks.update((snacks) => {
          snacks.delete(id);
          return snacks;
        });
      },
    };

    this.snacks.update((snacks) => snacks.set(id, config));

    const removingTimeout = setTimeout(() => config.prepForRemove(), 4300);
    const timeout = setTimeout(() => config.remove(), 5000);
  }

  remove(id: number) {
    this.snacks().get(id)?.remove();
  }

  info(message: string) {
    this.add({ message, type: 'info' });
  }

  error(message: string) {
    this.add({ message, type: 'error' });
  }

  success(message: string) {
    this.add({ message, type: 'success' });
  }
}
