<div class="fixed bottom-4 right-4 z-[100]">
  <div class="max-w-screen-sm mx-auto p-4 empty:hidden w-full flex flex-col gap-4">
    @for (item of bar.snacks().values(); track item.id) {
      <div
        class="snack {{ item.snack.type }} p-4 border rounded-md z-[100] transition-opacity"
        [ngClass]="{ 'opacity-0 duration-700': item.removing() }"
      >
        <div class="flex items-center justify-between">
          <div class="flex-grow">{{ item.snack.message }}</div>
          <button class="pl-2 h-6" (click)="item.remove()">
            <i class="fa-regular fa-xmark-large font-extrabold"></i>
          </button>
        </div>
      </div>
    }
  </div>
</div>
