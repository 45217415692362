import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

type NotificationType = 'error' | 'info' | 'success';

@Component({
  selector: 'app-static-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './static-notification.component.html',
  styleUrl: './static-notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticNotificationComponent {
  notificationType = input.required<NotificationType>();
}
