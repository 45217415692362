import { Injectable, inject, signal } from '@angular/core';
import { ApiService } from '@features/api';
import { ForgotPasswordResetRequest } from '@features/auth/models/forgot-password-reset-request';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import {
  ApiErrorResponse,
  GuestLoginResponse,
  LoginResponse,
  RegistrationRequest,
  UpdateDetailsRequest,
  User,
  UserResponse,
} from '../models';
import { JWTService } from './jwt.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  #apiService = inject(ApiService);
  #jwtService = inject(JWTService);

  readonly #authenticated = signal<boolean>(false);
  public authenticated = this.#authenticated.asReadonly();

  readonly #user = signal<User | null>(null);
  public user = this.#user.asReadonly();

  populate(): Observable<User> {
    return this.#apiService.get<UserResponse>('/user').pipe(
      map((user) => this.mapUser(user)),
      tap((user) => this.setUser(user)),
      catchError((error) => {
        this.#jwtService.clear();
        return of(error);
      }),
    );
  }

  createGuest(): Observable<User> {
    return this.#apiService.get<GuestLoginResponse>('/user/login-guest').pipe(
      tap(({ token }) => this.#jwtService.save(token)),
      switchMap(() => this.populate()),
    );
  }

  register(payload: RegistrationRequest): Observable<void> {
    return this.#apiService.post<void>('/user/register', payload);
  }

  validateAccount(token: string, unique_code: string) {
    return this.#apiService.post<void>('/user/validate-account', { token, unique_code });
  }

  login(email: string, password: string): Observable<User> {
    return this.#apiService.post<LoginResponse>('/user/login', { email, password }).pipe(
      tap(({ token }) => this.#jwtService.save(token)),
      switchMap(() => this.populate()),
    );
  }

  logout(): Observable<User> {
    return this.#apiService.get<void>('/user/logout').pipe(
      tap(() => this.clear()),
      switchMap(() => this.createGuest()),
    );
  }

  requestPasswordReset(email: string) {
    return this.#apiService.post<void>('/user/forgot-password', { email });
  }

  resetPassword(payload: ForgotPasswordResetRequest) {
    return this.#apiService.post<ApiErrorResponse>('/user/forgot-password-reset', payload);
  }

  updateDetails(payload: UpdateDetailsRequest): Observable<User> {
    return this.#apiService.post<UserResponse>('/user', payload).pipe(
      map((user) => this.mapUser(user)),
      tap((user) => this.setUser(user)),
    );
  }

  updatePassword(current_password: string, new_password: string, new_password_confirmation: string) {
    return this.#apiService.post<ApiErrorResponse>('/user/update-password', {
      current_password,
      new_password,
      new_password_confirmation,
    });
  }

  updateEmail(email: string): Observable<User> {
    return this.#apiService.post<UserResponse>('/user', { email }).pipe(
      map((user) => this.mapUser(user)),
      tap((user) => this.setUser(user)),
    );
  }

  private setUser(user: User) {
    this.#user.set(user);
    this.#authenticated.set(!user.is_guest);
  }

  private mapUser(user: UserResponse): User {
    const [dobDayOfMonth, dobMonth, dobYear] = user.birth_date.split('-');
    return {
      ...user,
      birth_date: new Date(`${dobYear}-${dobMonth}-${dobDayOfMonth}`),
    };
  }

  private clear(): void {
    this.#jwtService.clear();
    this.#user.set(null);
    this.#authenticated.set(false);
  }
}
