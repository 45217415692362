import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type ColorType = 'black-and-white' | 'primary';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  size = input<number>(40);
  color = input<ColorType>();
  disabled = input<boolean>(false);

  protected colorClasses = computed(() => {
    switch (this.color()) {
      case 'black-and-white':
        return this.disabled() ? 'bg-transparent text-black' : 'bg-transparent text-black';
        break;

      case 'primary':
        return this.disabled()
          ? 'bg-transparent text-form-field-color'
          : 'bg-form-field-color bg-opacity-10 text-primary';
        break;

      default:
        return this.disabled()
          ? 'bg-transparent text-form-field-color'
          : 'bg-form-field-color bg-opacity-10 text-form-field-color';
        break;
    }
  });
}
